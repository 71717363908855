import React from "react"
import Slider from "react-slick"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import WPLink from "components/shared/WPLink"
import SnowflakeBg from "components/shared/SnowflakeBg"
import swipe from "images/swipe.svg"
import snowflakeImg from "images/snowflake-prebook.svg"

const ContainerMaxStyled = styled(ContainerMax)`
    padding-right: 0;
    padding-left: 15px;

    @media ${media.lg} {
        padding-right: ${props => props.theme.bootstrapGutterWidthx2};
    }
`

const PreText = styled.div`
    text-align: center;
    padding: 0 15px;

    @media ${media.lg} {
        text-align: left;
    }

    ${props => props.pre && css`
        /* padding-right: ${props => props.theme.bootstrapGutterWidthx2}; */
        @media ${media.lg} {
            padding-right: 0;
        }
    `}

    h1, h2, h3, h4, h5 {
        font-family: ${props => props.theme.font.family.condensed} !important;
        font-weight: ${props => props.theme.font.weight.bold};

        strong {
            font-family: ${props => props.theme.font.family.condensed} !important;
        }

        ${props => props.pre && css`
            font-size: 1.875rem;

            @media ${media.sm} {
                font-size: 2.5rem;
            }
        
            @media ${media.xl} {
                font-size: 3.5rem;  
            }
        `}
    }
`

const SliderStyled = styled(Slider)`
    .slick-track {
        display: flex;
    }

    .slick-list {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        padding-left: 0 !important;

        @media ${media.lg} {
            padding: 0;
        }
    }

    .slick-slide {
        padding: 0 15px;	
        height: auto;

        > div {
            height: 100%;
            display: flex;
        }
    }
`

const RowStyled = styled(Row)`
    margin-right: 0;
`

const Item = styled.div`
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 3rem 15px;

    @media ${media.lg} {
        box-shadow: none;
        border-radius: 0;
        padding: 0 10px;
    }

    .iconWrap {
        display: flex;
        align-items: center;
        padding-bottom: .5rem;
        justify-content: center;
    
        @media ${media.lg} {
            justify-content: flex-start;
        }

        span {
            color: ${props => props.theme.colors.primary};
            font-family: ${props => props.theme.font.family.condensed} !important;
            font-weight: ${props => props.theme.font.weight.bold};
            font-size: 4rem;
            padding-right: 1rem;
        }
    }
`

const PreBook = (props) => {
	const { block } = props

	const getItems = block.items && block.items.map((item, i) => {
		return(
			<Item key={i}>
                <div className="iconWrap">
                    <span>{i+1}</span>
                    {(item.icon && item.icon.localFile) && (
                        <GatsbyImage
                            image={item.icon.localFile.childImageSharp.gatsbyImageData}
                            alt={item.icon.altText}
                        />
                    )}
                </div>
                {item.text && 
                    <PreText dangerouslySetInnerHTML={{ __html: item.text }} />
                }
            </Item>
		)
	})

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyload: false,
        arrows: false,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2.25,
                    slidesToScroll: 1,
                    // centerMode: true,
                    // centerPadding: '60px'
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]
    }

    return(
        <Container fluid className="position-relative px-0">
            <ContainerMaxStyled className="pt-4 pt-xl-5" maxWidth="1300">
                <SnowflakeBg src={snowflakeImg} alt="" />
                <RowStyled>
                    {block.text && 
                        <Col xs={12} lg={3}>
                            <PreText dangerouslySetInnerHTML={{ __html: block.text }} pre />
                        </Col>
                    }
                    <Col xs={12} lg={9} className="pr-0">
                        <SliderStyled {...settings}>
                            {getItems}
                        </SliderStyled>
                    </Col>
                </RowStyled>
            </ContainerMaxStyled>
            <ContainerMax maxWidth="1300" className="pb-4 pb-xl-5">
                <div className="text-center d-lg-none">
                    <img src={swipe} alt="Swipe to see more" width="100" />
                </div>
        
                {/*do we have a view all link?*/}
                {block.link &&
                    <div className="pt-5 pt-md-4 text-center">
                        <WPLink url={block.link.url} target={block.link.target} button color="secondary">
                            {block.link.title}
                        </WPLink>
                    </div>
                }
            </ContainerMax>
        </Container>
    )
}

export default PreBook