import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import queryString from "query-string"
import scrollToElement from "scroll-to-element"
import ContainerMax from "components/shared/ContainerMax"
import ActivityPlannerProgress from "./ActivityPlannerProgress"
import ActivityPlannerStep from "./ActivityPlannerStep"
import ActivityPlannerResults from "./ActivityPlannerResults"


export default function ActivityPlanner({block}) {
  const [activeStep, setActiveStep] = useState(1)
  const [mounted, setMounted] = useState(false)
  const [filter1, setFilter1] = useState(null)
  const [filter2, setFilter2] = useState(null)
  const [filter3, setFilter3] = useState(null)
  const [filter4, setFilter4] = useState(null)

  const data = useStaticQuery(graphql`
    query {
      allWp {
        edges {
          node {
            ...optionsFragment
          }
        }
      }
      allWpTtdActivityPlanner1 {
      # allWpTtdActivityPlanner1(sort: { fields: [menuOrder], order: ASC }) {
        edges {
          node {
            databaseId
            name
            acfActivityPlannerCategory {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 316, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      allWpTtdActivityPlanner2 {
        edges {
          node {
            databaseId
            name
            acfActivityPlannerCategory {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 316, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      allWpTtdActivityPlanner3 {
        edges {
          node {
            databaseId
            name
            acfActivityPlannerCategory {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 316, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      allWpTtdActivityPlanner4 {
        edges {
          node {
            databaseId
            name
            acfActivityPlannerCategory {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 316, quality: 95, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { 
    activityPlannerTitle1,
    activityPlannerTitle2,
    activityPlannerTitle3,
    activityPlannerTitle4,
    activityPlannerShortTitle1,
    activityPlannerShortTitle2,
    activityPlannerShortTitle3,
    activityPlannerShortTitle4
  } = data.allWp.edges[0].node.options.acfOptions

  useEffect(() => {
    if (activeStep === 5) {
      steps.forEach((step, i) => {
        const stepTitle = `Step ${i + 1}: ${step.title}`
        const selected = step.filters.edges.find(({node}) => node.databaseId === step.activeFilters[0]).node.name

        if (typeof window !== "undefined" && window.gtag) {
          window.gtag('event', 'hpww-activity-planner', {
            'event_category': stepTitle,
            'event_label': selected,
          });
        }
      })
    }
  }, [activeStep])

  useEffect(() => {
    // get query string, check if landing on page with predefined filters
    const urlString = typeof window !== 'undefined' ? window.location.search : ''

    // parse query string into json
    const selectedFilters = queryString.parse(urlString)
    
    // Do we have any filters in the url?
    if (Object.keys(selectedFilters).length !== 0) {
      // loop filters and set selectedFilters in state 
      for (const [key, value] of Object.entries(selectedFilters)) {
        let arr = []
        arr = value.split(',')
        arr = arr.map((x) => { 
          return parseInt(x)
        })

        if (key === "filter1") setFilter1(arr)
        if (key === "filter2") setFilter2(arr)
        if (key === "filter3") setFilter3(arr)
        if (key === "filter4") setFilter4(arr)
      }

      // all filters set in url, show final stage
      if (
        selectedFilters['filter1'] && 
        selectedFilters['filter2'] && 
        selectedFilters['filter3'] && 
        selectedFilters['filter4']
      ) {
        setActiveStep(5)
      }
    }

    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      const header = document.getElementById('siteHeader')
      let headerHeight = 0
      if (header) {
        headerHeight = header.offsetHeight
      }

      scrollToElement("#progress", {
        offset: - headerHeight - 20,
        duration: 500
      })
    }
  }, [activeStep])

  const reset = () => {
    setActiveStep(1)
    setFilter1(null)
    setFilter2(null)
    setFilter3(null)
    setFilter4(null)

    // remove any query string filters
    if (window.history.replaceState) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
      window.history.replaceState({ path: newurl }, '', newurl)
    }
  }


  const setFilter = (databaseId, filterKey, activeFilters, setFunction) => {
    let newSelectedFilters
    // Add or remove filter from array
    const id = parseInt(databaseId)
    
    // uncomment this to support multiple filters
    // if (activeFilters) {
    //   newSelectedFilters = activeFilters.includes(id)
    //   ? activeFilters.filter(i => i !== id) // remove item
    //   : [ ...activeFilters, id ]; // add item
    // } else {
      newSelectedFilters = [id]; // add item
    // }


    /** 
     * Apply filters for url, used so they can link with pre-defined filters
     * */
    // get query string
    const urlString = typeof window !== 'undefined' ? window.location.search : ''

    // parse query string into json
    const parsed = queryString.parse(urlString)

    // set filter with new filters
    parsed[filterKey] = newSelectedFilters

    // stringify filters to url query string
    const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' });

    // set new url with correct filters
    if (window.history.replaceState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + stringified
        window.history.replaceState({ path: newurl }, '', newurl)
    }

    // set state with new filters
    setFunction(newSelectedFilters)
  }

  const steps = [
    {
      title: activityPlannerTitle1,
      shortTitle: activityPlannerShortTitle1,
      filters: data.allWpTtdActivityPlanner1,
      activeFilters: filter1,
      setFunction: (val) => setFilter1(val)
    },
    {
      title: activityPlannerTitle2,
      shortTitle: activityPlannerShortTitle2,
      filters: data.allWpTtdActivityPlanner2,
      activeFilters: filter2,
      setFunction: (val) => setFilter2(val)
    },
    {
      title: activityPlannerTitle3,
      shortTitle: activityPlannerShortTitle3,
      filters: data.allWpTtdActivityPlanner3,
      activeFilters: filter3,
      setFunction: (val) => setFilter3(val)
    },
    {
      title: activityPlannerTitle4,
      shortTitle: activityPlannerShortTitle4,
      filters: data.allWpTtdActivityPlanner4,
      activeFilters: filter4,
      setFunction: (val) => setFilter4(val)
    },
  ]

  return(
    <ContainerMax className="py-5">
      
      <div id="progress">
        {activeStep !== 5 && (
          <ActivityPlannerProgress
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )}
      </div>

      {steps.map((step, i) => {
        const stepNum = i + 1
        if (activeStep === stepNum) {
          return(
            <ActivityPlannerStep 
              key={`step${stepNum}`}
              title={step.title} 
              filters={step.filters}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              activeFilters={step.activeFilters}
              setFilter={(node) => {
                setFilter(node.databaseId, `filter${stepNum}`, step.activeFilters, step.setFunction)
              }}
            />
          )
        } else return null
      })}

      {activeStep === 5 && (
        <ActivityPlannerResults
          activeFilters={[filter1, filter2, filter3, filter4]} 
          reset={reset}
          sharePage={block.sharePage}
        />
      )}
    </ContainerMax>
  )
}