import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "styled-components"

import WPLink from "components/shared/WPLink"
import { media } from "utils/Media"

const ActivityPlannerBannerStyles  = styled.section`
  width: 100%;
  margin: 0 auto 2rem auto;
  overflow: hidden;
  max-width: 1300px;
  padding: 0 30px;
  @media ${media.xxl} {
    max-width: 1600px;
  }
  .wrapper {
    background: url(${props => props.bg});
    background-position: center;
    padding: 2rem 0;
    max-width: 1920px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    
    section {
      width: 90%;
      margin: 0 auto;
      max-width: 1350px;
      text-align: center;
      @media ${media.md} {
        display: grid;
        grid-template-columns:  60% 1fr;
      }
      @media ${media.xl} {
        display: flex;
        align-items: center;
        justify-content:center;
        gap: 5rem;
      }
      h3 {
        font-size: 25px;
        color: #fff;
        font-family: boucherie-sans, sans-serif;
        font-style: italic;
        font-weight: 700;
        margin-bottom: 1rem;
        text-align: center;
        font-size: clamp(1.5625rem, 1.125rem + 2.1875vw, 3.75rem);
        /* font-size: clamp(1.8125rem, 1.425rem + 1.9375vw, 3.75rem); */
        @media ${media.md} {
          /* font-size: 40px; */
          margin-bottom: 0rem;
          text-align: left;
        }
        @media ${media.xl} {
          
        }
        @media ${media.xxl} {
          /* font-size: 60px; */
        }
        
      }
      .button {
        /* max-width: 350px; */
        @media ${media.md} {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        @media ${media.xl} {
          a {
            font-size: 1.6rem!important;
          }
        }
      }
    }
    .bg-image {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
`

export default function ActivityPlannerBanner({ block }) {

  const { backgroundImage, button, title} = block

  console.log(backgroundImage)

  return (
    <ActivityPlannerBannerStyles  bg={backgroundImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src}>
      <div className='wrapper'>
        <section>
        {title && (
          <h3>{title}</h3>
        )}
        {button && (
          <div className='button'>
            <WPLink 
            url={`${button.url}`} 
            target={button.target} 
            button 
            color="white"
        >
            {button.title}
        </WPLink>
        </div>
        )}
        </section>
      </div>
    </ActivityPlannerBannerStyles>
  )
}
